<!-- 选科专业 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-input__icon {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getListPage,
    addSubject,
    editSubject,
    deleteSubject,
    updateSubjectQyzt,
    getDetail,
    addClassSubject,
    deleteClassSubject,
    editClassSubject,
    classificationEasyList,
    updateClassificationSfms
  } from "@/api/admin/base/classification.js"
  import {
    getSubListEasy
  } from "@/api/admin/base/subject.js"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {

        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        checked: true,
        defaultvalue: 1,
        currentpage: 1,
        showmodal: false,
        showmodal2: false,
        showmodal3: false,
        exportTitle: "考试专业导出",
        importTitle: "考试专业导入",
        tableList: [],
        dialogTitle: "添加专业",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        },
        addForm: {},
        detailForm: {
          lbmc: "",
          flmc: "",
          fjxkdm: "",
          xkmc: "",
          xkdm: "",
          xksm: "",
          kmmc: "",
          kmdm: "",
        },
        options: [],
        kmmc: "",
        kmdm: "",
        module: "KSXKFL",
        list: [],
        loading: false,
        form: {
          kmdm: "",
          kmmc: "",
        },
        select: {
          lbbm: "",
          flbm: "",
          fjxkdm: "",
          jbmc: "",
        },
        disabled: false,
        tabList2: [],
        classList: [],
        secondList: [],
        classificationList: []
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteSubject(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      // 获取数据列表
      getList() {
        let select = JSON.parse(JSON.stringify(this.select))
        if (select.fjxkdm == "") {
          select.fjxkdm = "0"
        }
        getListPage({
          ...this.pageData,
          ...select,
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      // 改变启用状态
      changeQyzt(sid, val, name) {
        let obj = {
          sid: sid,
        }
        obj[name] = val
        updateSubjectQyzt(obj).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功!'
            });
          }
        })
      },
      changeMszt(sid, val, name) {
        let obj = {
          sid: sid,
        }
        obj[name] = val
        updateClassificationSfms(obj).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功!'
            });
          }
        })
      },
      // 添加类别
      addMaintype() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        addSubject(formDate).then(res => {
          if (res.status) {
            this.success(res)
          }

        })
      },
      editMaintype() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sid = this.addForm.sid
        editSubject(formDate).then(res => {
          if (res.status) {
            this.success(res)
          }
        })
      },

      success(res) {
        this.$notify({
          type: 'success',
          message: res.message ? res.message : '数据操作成功',
          duration: 3000,
        })
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          fjxkdm: "",
          xkdm: "",
          xkmc: "",
          qyzt: true,
          sfms: false,
          pxh: 0,
          xksm: "",
        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加专业"
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          fjxkdm: "",
          xkdm: "",
          xkmc: "",
          qyzt: true,
          sfmf: false,
          pxh: 0,
          xksm: "",
        }
        if (this.select.fjxkdm) {
          this.addForm.fjxkdm = this.select.fjxkdm
        } else {
          this.getBm(this.module, "").then(e => {
            this.addForm.xkdm = e
          })
        }

      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改专业"
        this.disabled = true
        let flbm = this.addForm.flbm
        this.getClassList(flbm)

      },
      submit() {
        if (this.addForm.sid) {
          this.editMaintype()
        } else {
          this.addMaintype()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList()
      },
      ShowDetails(sid) {
        // this.showmodal2 = true
        getDetail(sid).then(res => {
          if (res.status) {
            this.detailForm = res.data
            this.tabList2 = res.data.subjectList
            this.getSubject(this.detailForm.flbm)
          }

        })
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      // 选科筛选
      getClassList(params) {
        classificationEasyList({
          flbm: params,
          fjxkdm: 0
        }).then(res => {
          if (res.status) {
            this.classificationList = res.data
          }
        })
      },
      // 添加选科科目
      addClassSub() {
        addClassSubject({
          kmdm: this.kmdm,
          xkdm: this.detailForm.xkdm
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '科目添加成功'
            });
            this.ShowDetails(this.detailForm.sid)
          }
        })
      },
      // 删除选科科目
      deleteClassSub(sid) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteClassSubject(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.ShowDetails(this.detailForm.sid)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editClassSub(item) {
        let copyData = JSON.parse(JSON.stringify(item))
        this.form = copyData
        this.form.sfbz = copyData.sfbz / 100
      },
      subClass(k) {
        let from = JSON.parse(JSON.stringify(k))
        from.xkdm = this.detailForm.xkdm
        from.sfbz = k.sfbz * 100
        editClassSubject(from).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
          this.showmodal3 = false
          this.ShowDetails(this.detailForm.sid)
        })
      },
      getflmc() {
        let val = this.addForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.addForm.flmc = obj.flmc
        this.getClassList(val)

      },

      getlbmc() {
        let val = this.addForm.lbbm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.addForm.lbmc = obj.lbmc
        this.getSecondList({
          lbbm: val
        });
      },
      getfjxkmc() {
        let val = this.addForm.fjxkdm
        let obj = this.classificationList.find(k => {
          return k.xkdm === val
        })
        this.addForm.fjxkmc = obj.xkmc
        this.getBm(this.module, "xk").then(e => {
          this.addForm.xkdm = e
        })
      },
      getKmmc() {
        let val = this.form.kmdm
        let obj = this.options.find(k => {
          return k.kmdm === val
        })
        this.form.kmmc = obj.kmmc
      },
      changeflmc() {
        let val = this.select.lbbm
        this.getSecondList({
          lbbm: val
        })
      },
      changeXkmc() {
        let val = this.select.flbm
        this.getClassList(val)
      },
      getSubject(params) {
        getSubListEasy({
          flbm: params
        }).then(res => {
          if (res.status) {
            this.options = res.data
          }
        })
      },

      // 获取当前页父级选科下的选科
      // getZjxk(row) {
      //   if (row.fjxkdm == "0") {
      //     this.select.fjxkdm = row.xkdm;
      //     this.getList()
      //   }
      // },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }
    },

    mounted() {
      this.getList()
      this.getClassListPage()
      this.getSecondList()
      this.getClassList()

    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 " style="width: 15%;" clearable placeholder="考试类别" v-model="select.lbbm"
                  size="small" @change="changeflmc">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.value" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 16%;" clearable placeholder="考试二级类别" v-model="select.flbm"
                  size="small" @change="changeXkmc">
                  <el-option :value="item.flbm" v-for="item in secondList" :key="item.value" :label="item.flmc">
                    {{item.flmc}}
                  </el-option>
                </el-select>
                <!-- <el-select class=" mr-2 " style="width: 15%;" clearable placeholder="请选择专业" v-model="select.fjxkdm"
                  size="small">
                  <el-option :value="item.xkdm" v-for="item in classificationList" :key="item.value" :label="item.xkmc">
                    {{item.xkmc}}
                  </el-option>
                </el-select> -->
                <input placeholder="请输入级别名称" v-model="select.jbmc" class="h30 form-control border-blue w-15 mr-2"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加专业</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th>考试二级类别</th>
                    <th style="width: 7%;">专业代码</th>
                    <th style="width:15%">专业名称</th>
                    <!-- <th style="width:10%">所属父级选科</th> -->
                    <th>级别</th>
                    <th style="width: 7%;">专业</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width:8%">是否免试</th>
                    <th style="width:4%">排序号</th>
                    <th style="width:10%">查看科目</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td> {{obj.flmc}}</td>
                    <td>{{obj.xkdm}}</td>
                    <td>
                      <div style="cursor: pointer;" class="font-blue" @click="editItem(obj)">{{obj.xkmc}}</div>
                    </td>
                    <!-- <td>{{obj.fjxkmc?obj.fjxkmc:''}}</td> -->
                    <td>{{obj.jbmc}}</td>
                    <td>{{obj.zymc}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check"
                        @change="changeQyzt(obj.sid,obj.qyzt,'qyzt')">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.sfms" switch class=" switch-check"
                        @change="changeMszt(obj.sid,obj.sfms,'sfms')">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.pxh}}</td>
                    <td>
                      <el-button type="primary" size="mini" v-b-modal.majorDetails @click="ShowDetails(obj.sid)">查看详情/科目
                      </el-button>
                    </td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click="editItem(obj)"></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.xkmc)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!--添加选科-->
    <b-modal id="addMajors" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属类别：</label>
              <div class=" col-sm-10 p-0 flexList">
                <select name="lbbm" class="form-control form-select w-100" v-model="addForm.lbbm" @change="getlbmc">
                  <option value="" disabled>请选择所属类别</option>
                  <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
                </select>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属分类：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.flbm" @change="getflmc">
                  <option value="" disabled>请选择分类</option>
                  <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div class="d-flex check-distri mb-3"><label>上级选科：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.fjxkdm" @change="getfjxkmc">
                  <option value="" disabled>所属父级选科/专业</option>
                  <option :value="item.xkdm" v-for="item in classificationList" :key="item.value" :label="item.xkmc">
                    {{item.xkmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>专业代码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="fjxk" value="" maxlength="10" :disabled="this.disabled"
                  placeholder="专业代码,不能重复" v-model="addForm.xkdm" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>专业名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" placeholder="请输入报考专业名称" v-model="addForm.xkmc"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>级别代码：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" placeholder="请输入级别代码" v-model="addForm.jbm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>级别名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" placeholder="请输入级别名称" v-model="addForm.jbmc"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属代码：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="10" placeholder="请输入所属专业代码" v-model="addForm.zym"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属专业：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="50" placeholder="请输入所属专业名称" v-model="addForm.zymc"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" id="exampleRadios1" value=true
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" id="exampleRadios2" value=false v-model="addForm.qyzt"
                    class="form-check-input"><label for="exampleRadio2" class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否免试：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" id="exampleRadios3" value=true
                    v-model="addForm.sfms" class="form-check-input"><label for="exampleRadios3"
                    class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" id="exampleRadios4" value=false v-model="addForm.sfms"
                    class="form-check-input"><label for="exampleRadio4" class="form-check-label">否</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>排序数值：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input id="number" value="" type="number" v-model="addForm.pxh" name="number" class="w-100 h30"
                  placeholder="序号越小排序越靠前">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>专业说明：</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols="" class="form-control" v-model="addForm.xksm" placeholder="多行输入"
                  maxlength="100"></textarea>

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 专业详情 -->
    <b-modal id="majorDetails" v-model="showmodal2" centered title="专业详情" title-class="font-18" hide-footer>
      <div>
        <el-form ref="detailForm" :model="detailForm">
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>所属类别：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.lbmc}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>所属分类：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.flmc}}</div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div class="check-distri mb-2 major-details"><label>上级选科：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.fjxkdm}}</div>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>专业名称：</label>
              <div class=" col-sm-10">
                <div class="line1"> {{detailForm.xkmc}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>专业说明：</label>
              <div class=" col-sm-10">
                <div class="line1">{{detailForm.xksm}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3 mt-2">
              <div class="col-sm-10 pl-0 flexList">
                <el-select v-model="kmdm" filterable clearable placeholder="可输入科目名称查找" class="w-50 h30">
                  <el-option v-for="item in options" :key="item.value" :label="item.kmmc" :value="item.kmdm">
                  </el-option>
                </el-select>
                <b-button variant="info" class="flexList w-md ml-2 h30" @click="addClassSub"><i
                    class="iconfont icon-plus-circle mr-2" style="font-size: 24px;"></i>添加考试科目</b-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="check-distri mb-2 major-details"><label>考试科目</label>
              <div class=" col-sm-10">
              </div>
            </div>
          </el-form-item>
          <table class="table table-hover table-borderless ">
            <thead>
              <tr v-for="(item,index) in tabList2" :key="index">
                <td style="width: 12%;">科目{{index+1+(pageData.pageNum-1)*pageData.pageSize}} </td>
                <td class="font-blue">{{item.kmdm}}--{{item.kmmc}}</td>
                <td>{{item.sfbz/100}}</td>
                <td class="tab-icon" style="width: 5%;"><i class="iconfont icon-edit-two align-middle mr-1"
                    v-b-modal.editSubject @click="editClassSub(item)"></i><i
                    class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                    @click="deleteClassSub(item.sid)"></i>
                </td>
              </tr>

            </thead>
          </table>
        </el-form>
      </div>




    </b-modal>
    <!-- 科目修改弹窗 -->
    <b-modal id="editSubject" v-model="showmodal3" centered title="专业科目" title-class="font-18" hide-footer>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="科目代码">
          <el-input v-model="form.kmdm" disabled></el-input>
        </el-form-item>
        <el-form-item label="科目名称">
          <el-select v-model="form.kmdm" filterable class="w-100 h30" @change="getKmmc">
            <el-option v-for="item in options" :key="item.value" :label="item.kmmc" :value="item.kmdm">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费标准">
          <el-input v-model="form.sfbz"></el-input>
        </el-form-item>
      </el-form>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="subClass(form)">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal3 = false">取消</button>
      </div>
    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
